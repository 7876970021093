import { useState } from 'react';
import {
  generarRecetaElectronica,
  getReporteRecetaElectronica,
} from '../../../services/RecetaElectronica/RecetaElectronica';
import { CANTIDAD_RECETAS_OPTIONS } from '../../Busquedas/CantidadRecetasCombo';
import { getFechaFormateada } from '../../../@iosper/utils/Format';

const rpModel = {
  cie10: null,
  medicamento: null,
};

// Init data receta
const initRecetaData = {
  afiliado: null,
  tipoTratamiento: {
    idTipoTratamiento: 1,
    descripcion: 'Normal',
    codigo: '1',
  },
  cantidadRecetas: {
    cantidad: 1,
  },
  fechaInicio: getFechaFormateada(),
  observacion: '',
  recetaControlada: {
    domicilio: '',
    telefono: '',
  },
  rpList: [
    {
      cie10: null,
      medicamento: null,
    },
  ],
};

export const useReceta = (initReceta = initRecetaData) => {
  const [recetaData, setRecetaData] = useState(initReceta);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: '',
  });
  const [isLoadingGenerarReceta, setIsLoadingGenerarReceta] = useState(false);
  const [disabledTipoTratamiento, setDisabledTipoTratamiento] = useState(true);
  const [disabledCantidadRecetas, setDisabledCantidadRecetas] = useState(true);
  const [isVentaControlada, setIsVentaControlada] = useState(
    initReceta?.tipoRecetaElectronica?.codigo === '2' ? true : false,
  );

  /**
   * Evento que setea el objeto recetaData a su estado inicial
   */
  const onResetRecetaData = () => {
    setRecetaData({
      afiliado: null,
      tipoTratamiento: {
        idTipoTratamiento: 1,
        descripcion: 'Normal',
        codigo: '1',
      },
      cantidadRecetas: {
        cantidad: 1,
      },
      fechaInicio: getFechaFormateada(),
      observacion: '',
      recetaControlada: {
        domicilio: '',
        telefono: '',
      },
      rpList: [
        {
          cie10: null,
          medicamento: null,
        },
      ],
    });
  };

  /**
   * Evento que setea el objeto recetaData a su estado inicial
   */
  const onSendMessageEvent = ({ status = 'success', message = '' }) => {
    setMessageStatus({ status, message });
  };

  /**
   * Evento selección afiliado. Seteamos el nuevo estado de afiliado
   * Setea por default el tipo de tratamiento en "Normal"
   * @param {object} afiliado
   */
  const onSelectAfiliado = (afiliado) => {
    setRecetaData({ ...recetaData, afiliado: afiliado });
    setDisabledTipoTratamiento(afiliado ? false : true);
  };

  /**
   * Evento selección tipo tratamiento. Seteamos el nuevo estado de tipTratamiento
   * @param {object} tipoTratamiento
   */
  const onSelectTipoTratamiento = (tipoTratamiento) => {
    setRecetaData({ ...recetaData, tipoTratamiento });
    setDisabledCantidadRecetas(!tipoTratamiento);

    if (tipoTratamiento?.codigo === '1') {
      setDisabledCantidadRecetas(true);
      setRecetaData({
        ...recetaData,
        tipoTratamiento,
        cantidadRecetas: CANTIDAD_RECETAS_OPTIONS[0],
      });
    }
  };

  /**
   * Evento selección cantidad de recetas
   * @param {object} cantidadRecetas
   */
  const onSelectCantidadRecetas = (cantidadRecetas) => {
    setRecetaData({ ...recetaData, cantidadRecetas: cantidadRecetas });
  };

  const onSelectFechaInicio = (fechaInicio) => {
    setRecetaData({ ...recetaData, fechaInicio: fechaInicio });
  };

  const onSelectUltimaRecetaUsada = (ultimaRecetaUsada) => {
    setRecetaData({ ...ultimaRecetaUsada, fechaInicio: '' });
  };

  // EVENTOS QUE SE EJECUTAN CON CADA ITEM DEL RP
  // Para controlar la lista de objetos RP, cada objeto contiene
  // cie10, medicamento, cantidad, etc

  /**
   * Al hacer clic en el botón ADD se crea un nuevo objeto
   * y se lo agrega en el array de RP
   */
  const onAddNewRecipe = () => {
    let newRecipe =
      recetaData.rpList.length > 0 ? recetaData.rpList[recetaData.rpList.length - 1] : rpModel;
    newRecipe = { ...newRecipe, medicamento: null };
    setRecetaData({ ...recetaData, rpList: [...recetaData.rpList, newRecipe] });
  };

  /**
   * Al hacer clic en el botón de eliminar item RP, este item
   * pasa el indice que se está eliminando y con esto recorremos
   * el array de RP y eliminamos dicho objeto de la lista
   * @param {int} indice
   */
  const onDeleteRecipe = (indice) => {
    let list = recetaData.rpList.filter((item, key) => key !== indice);
    if (list.length < 1) {
      list.push(rpModel);
    }
    setRecetaData({ ...recetaData, rpList: list });
    onCheckIsVentaControlada(list);
  };

  /**
   * Al realizar un cambio de datos en el objeto RP, se ejecuta
   * el evento de actualización que envía indice y objeto nuevo a actualizar.
   * Se busca el elemento en la lista y se remplaza actualizando la misma.
   * @param {int} indice
   * @param {Object} newValue
   */
  const onChangeRecipe = (indice, newValue) => {
    let list = recetaData.rpList;
    list[indice] = newValue;
    onCheckIsVentaControlada(list);
    setRecetaData({ ...recetaData, rpList: list });
  };

  /**
   * Controla si es una venta controlada y levanta bandera de aviso
   */
  const onCheckIsVentaControlada = (rpList = []) => {
    if (!rpList || rpList?.length < 1) return;
    let result = rpList?.filter(({ medicamento }) => medicamento?.isControlado);

    setIsVentaControlada(Array.isArray(result) && result?.length > 0);
  };

  /**
   * Validación de si exste un medicamento repetido
   * @param {Object} medicamento
   * @param {int} indice
   * @returns {Boolean}
   */
  const isMedicamentoRepetido = (medicamento, indice) => {
    if (recetaData.rpList.length <= 1) return true; // no puede se repetido por ser el unico RP
    let result = true;
    recetaData.rpList.forEach((item, idx) => {
      if (idx !== indice && item?.medicamento?.idMedicamento === medicamento.idMedicamento) {
        result = false;
      }
    });
    return result;
  };

  /**
   * Evento que genera la receta y retorna los nuevos valores guardados
   * @returns {Object} | null
   */
  const onGenerarReceta = async () => {
    setIsLoadingGenerarReceta(true);
    try {
      const res = await generarRecetaElectronica(recetaData);

      if (res) {
        let idsRecetas = [];
        let descripcionIdsCofaer = '';
        res.recetas.forEach(function (receta) {
          idsRecetas.push(receta.idRecetaElectronica);
          descripcionIdsCofaer += receta.numero + '/';
        });

        descripcionIdsCofaer = descripcionIdsCofaer.slice(0, descripcionIdsCofaer.length - 1);

        const urlReporte = await getReporteRecetaElectronica({
          idsRecetas: idsRecetas,
        });

        onSendMessageEvent({ status: 'success', message: 'Receta/s electrónica/s generada/s.' });
        onResetRecetaData();
        setIsLoadingGenerarReceta(false);

        let afiliado = res.afiliado;
        afiliado.correo = afiliado.correo ? afiliado.correo : recetaData.afiliado.correo;

        let newData = {
          ...recetaData,
          recetaElectronica: res.recetas[0],
          profesional: res.profesional,
          afiliado: afiliado,
          plan: res.plan,
          grupoPlan: res.grupoPlan,
          estado: res.estado,
          descripcionIds: descripcionIdsCofaer,
          idsRecetas: idsRecetas,
          idsRecetasAsociadas: res.idsRecetasAsociadas,
          urlReporte: urlReporte,
        };

        return newData;
      }
    } catch (e) {
      setIsLoadingGenerarReceta(false);
    }

    return null;
  };

  /**
   * Recibe el evento de cambio de dirección y actualiza el estado
   * @param {object} event
   */
  const onChangeDomicilio = ({ target }) => {
    const { value } = target;
    setRecetaData({
      ...recetaData,
      recetaControlada: {
        ...recetaData.recetaControlada,
        domicilio: value,
      },
    });
  };

  /**
   * Recibe el evento de cambio de dirección y actualiza el estado
   * @param {object} event
   */
  const onChangeObservacion = ({ target }) => {
    const { value } = target;
    setRecetaData({
      ...recetaData,
      observacion: value,
    });
  };

  /**
   * Recibe el evento de cambio de telefono y actualiza el estado
   * @param {object} event
   */
  const onChangeTelefono = ({ target }) => {
    const { unmaskedValue } = target;
    setRecetaData({
      ...recetaData,
      recetaControlada: {
        ...recetaData.recetaControlada,
        telefono: unmaskedValue,
      },
    });
  };

  const onCargarReceta = (data) => {
    setRecetaData({ ...data, fechaInicio: getFechaFormateada() });
  };

  return {
    recetaData,
    onSelectUltimaRecetaUsada,
    messageStatus,
    onResetRecetaData,
    onSelectAfiliado,
    onSelectTipoTratamiento,
    onSelectCantidadRecetas,
    onSelectFechaInicio,
    onAddNewRecipe,
    onDeleteRecipe,
    onChangeRecipe,
    onGenerarReceta,
    onChangeDomicilio,
    onChangeTelefono,
    onChangeObservacion,
    isLoadingGenerarReceta,
    isMedicamentoRepetido,
    disabledTipoTratamiento,
    disabledCantidadRecetas,
    isVentaControlada,
    onCargarReceta,
  };
};
