export const currencyFormatter = () => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
  });
};

export const dateFormatter = () => {
  return new Intl.DateTimeFormat('es-AR', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
};

/**
 * Recibe un string date yyyy-mm-dd y devuelve dd de MM de yyyy
 *
 * @param String
 * @return String
 */
export const dateBirthFormatter = (date = '', type = 'yyyy-mm-dd') => {
  if (!date || date?.trim()?.length < 1) {
    return null;
  }
  const [day, month, year] = date?.split('/') || [];
  if (type === 'dd/mm/yyyy' && (!day || !month || !year)) {
    return null;
  }
  switch (type) {
    case 'yyyy-mm-dd':
      return new Intl.DateTimeFormat('es-AR', {
        dateStyle: 'long',
      }).format(new Date(`${date} 00:00:00`));
    case 'dd/mm/yyyy':
      return new Intl.DateTimeFormat('es-AR', {
        dateStyle: 'long',
      }).format(new Date(`${year}-${month}-${day} 00:00:00`));
    default:
      return date;
  }
};

export const format = (date) => {
  if (!date) {
    return null;
  }

  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  return yyyy + mm + dd;
};

export const getFechaFormateada = (options = {}) => {
  const { date = new Date(), format = 'yyyy-MM-dd' } = options;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(date.getDate()).padStart(2, '0');

  // Reemplazar correctamente sin afectar posibles repeticiones no deseadas
  return format.replace(/yyyy/g, year).replace(/MM/g, month).replace(/dd/g, day);
};

/**
 * Función que transforma una cadena en base64 a Blob.
 * para PDF, imágenes, etc.
 * @param String
 * @param String
 * @param int
 * @return Blob
 */
export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
